<template>
  <div>
    <b-card>
      <AgGrid
        ref="ISFHBOLGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'Create a New HBOL'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewHBOL()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New HBOL</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateHBOLModal"
      ref="CreateHBOL"
      title="New HBOL"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="NewHBOLRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Bill Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Bill Type:</b></i></label>
                  <v-select
                    id="NewBillType"
                    v-model="line.BillType"
                    :options="BillTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Bill #"
                rules="required|max:45"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Bill #:</b></i></label>
                  <b-form-input
                    id="NewBillNumber"
                    v-model="line.BOLNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="BOL Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewHBOL()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateHBOLModal"
      ref="UpdateHBOL"
      title="Update HBOL"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdatetHBOLRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Bill Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Bill Type:</b></i></label>
                  <v-select
                    id="BillType"
                    v-model="line.BillType"
                    :options="BillTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Bill #"
                rules="required|max:45"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Bill #:</b></i></label>
                  <b-form-input
                    id="BillNumber"
                    v-model="line.BOLNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="BOL Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateHBOL()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIISFHBOLBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'IsfHBOL',
  components: {
    AgGrid,
  },
  props: {
    isfid: { type: String, default: '' },
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      BillTypeOption: [
        { text: 'Regular Bills', value: 'OB' },
        { text: 'House BOL', value: 'BM' },
      ],
      line: {
        HBOLId: '',
        BillType: '',
        BOLNumber: '',
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISFHBOL')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Bill Type', field: 'hbolType', minWidth: 100 },
      { headerName: 'Bill #', field: 'hbol', minWidth: 100 },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFHBOLGrid.saveState('ISFHBOL') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFHBOLGrid.saveState('ISFHBOL') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFHBOLGrid.saveState('ISFHBOL') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFHBOLGrid.saveState('ISFHBOL') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(hbolId) {
      this.ResetObject()
      this.$refs.UpdateHBOL.show()
      this.LoadHBOL(hbolId)
    },
    DoubleClickOpenPopup(params) {
      this.ResetObject()
      this.$refs.UpdateHBOL.show()
      this.LoadHBOL(params.data.id)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadHBOLList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    CreateNewHBOL() {
      this.ResetObject()
      this.$refs.CreateHBOL.show()
    },
    LoadHBOLList() {
      return axios.get(`/isf/${this.isfid}/detail/hbl/list`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ISFHBOLGrid.ResetColumns()
        })
    },
    LoadHBOL(HBOLId) {
      axios.get(`/isf/${this.isfid}/detail/hbl/${HBOLId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response[0]
        this.line.HBOLId = respdata.id
        this.line.BOLNumber = respdata.hbol
        this.line.BillType = this.BillTypeOption.find(option => option.value === respdata.hbolType)
        this.LoadingEnd()
      })
    },
    SaveNewHBOL() {
      this.$refs.NewHBOLRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFHBOLModel = {
            HBOL: this.line.BOLNumber,
            HBOLType: this.line.BillType.value,
          }
          axios.post(`/isf/${this.isfid}/detail/hbl`, ISFHBOLModel).then(() => {
            this.LoadingEnd()
            this.$refs.CreateHBOL.hide()
            this.DisplaySuccess('HBOL data has been saved.').then(() => {
              this.LoadHBOLList()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateHBOL.hide()
              this.DisplayError('New HBOL cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New HBOL cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateHBOL() {
      this.$refs.UpdatetHBOLRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFHBOLModel = {
            HBOL: this.line.BOLNumber,
            HBOLType: this.line.BillType.value,
          }
          axios.put(`/isf/${this.isfid}/detail/hbl/${this.line.HBOLId}`, ISFHBOLModel).then(() => {
            this.LoadingEnd()
            this.$refs.UpdateHBOL.hide()
            this.DisplaySuccess('HBOL data has been updated.').then(() => {
              this.LoadHBOLList()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.UpdateHBOL.hide()
              this.DisplayError('HBOL cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('HBOL cannot be updated.', 'Please check data requirements.')
        }
      })
    },
    Delete(hbolId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/isf/${this.isfid}/detail/hbl/${hbolId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('HBOL has been removed.')
              .then(() => {
                // redirect
                this.LoadHBOLList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove HBOL.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetObject() {
      this.line = {
        HBOLId: '',
        BillType: '',
        BOLNumber: '',
      }
    },
  },
}
</script>
