<template>
  <div>
    <AgGridNoEdits
      ref="ISFResponseGrid"
      :grid="aggrid"
      @LoadAll="LoadAll"
    />
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  name: 'IsfResponse',
  components: {
    AgGridNoEdits,
  },
  props: {
    isfid: { type: String, default: '' },
  },
  data() {
    return {
      aggrid: [],
      MsgStatus: '',
      MsgErrorCode: '',
      MsgMessage: '',
      color: '',

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISFResponse')
    this.aggrid.columnDefs = [
      { headerName: 'Line Identifier', field: 'lineIdentifier', minWidth: 155 },
      { headerName: 'Line Code', field: 'lineCode', minWidth: 75 },
      {
        headerName: 'Line Status', field: 'lineStatus', minWidth: 155, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Error Code', field: 'errorCode', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Line Message', field: 'lineMessage', minWidth: 175, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Added Date', field: 'addedDate', minWidth: 100,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFResponseGrid.saveState('ISFResponse') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFResponseGrid.saveState('ISFResponse') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFResponseGrid.saveState('ISFResponse') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFResponseGrid.saveState('ISFResponse') }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadResponse()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadResponse() {
      return axios.get(`/isf/${this.isfid}/detail/response`)
        .then(rowData => {
          // eslint-disable-next-line no-nested-ternary
          this.aggrid.rowData = this.CheckNull(rowData.data[0]) ? rowData.data[0].msgErrorCode === '02' ? [] : rowData.data : []
          this.MsgMessage = this.CheckNull(rowData.data[0]) ? rowData.data[0].msgMessage : 'Pending'
          this.MsgErrorCode = this.CheckNull(rowData.data[0]) ? rowData.data[0].msgErrorCode : '00'
          this.MsgStatus = this.CheckNull(rowData.data[0]) ? rowData.data[0].msgStatus : 'Pending'
          // eslint-disable-next-line no-nested-ternary
          this.color = this.CheckNull(rowData.data[0]) ? rowData.data[0].msgErrorCode === '01' ? 'red' : rowData.data[0].msgErrorCode === '02' ? 'green' : 'darkorange' : ''
        }).then(() => {
          this.$refs.ISFResponseGrid.ResetColumns()
        })
    },
  },
}
</script>
