<template>
  <div>
    <b-card title="CBP Actions">
      <b-button-toolbar justify>
        <div>
          <b-button
            v-b-tooltip.hover="'Remove ISF in CBP'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-1"
            @click="sendDeleteISF()"
          ><feather-icon
             icon="Trash2Icon"
             class="mr-50"
           />
            <span class="align-middle">Remove ISF</span></b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Send ISF Data'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SendISF()"
          ><feather-icon
             icon="SendIcon"
             class="mr-50"
           />
            <span class="align-middle">File ISF</span></b-button>
        </div>
      </b-button-toolbar>
    </b-card>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <h4>{{ isf.ISFNumber }}</h4>
          <h6>Status: {{ isf.Status }}</h6>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Delete in Database (Does not update in CBP system)'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteISF()"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="12">
          <b-tabs lazy>
            <b-tab
              ref="HeaderTab"
              title="Header"
              @click="UpdateComponents()"
            >
              <ISFDetailHeader
                :key="index"
                :isfid="isfid"
              />
            </b-tab>
            <b-tab
              ref="PartyTab"
              title="Party"
              @click="UpdateComponents()"
            >
              <ISFDetailParty
                :key="index"
                :isfid="isfid"
                :customerid="isf.CustomerId"
              />
            </b-tab>
            <b-tab
              ref="RefTab"
              title="References"
              @click="UpdateComponents()"
            >
              <ISFDetailReference
                :key="index"
                :isfid="isfid"
                :bondactivity="isf.BondActivity"
                :bondtype="isf.BondType"
                :shipmenttype="isf.ShipmentType"
              />
            </b-tab>
            <b-tab
              ref="HBOLTab"
              title="HBOLs"
              @click="UpdateComponents()"
            >
              <ISFDetailHBOL
                :key="index"
                :isfid="isfid"
              />
            </b-tab>
            <b-tab
              ref="ContainerTab"
              title="Containers"
              @click="UpdateComponents()"
            >
              <ISFDetailContainer
                :key="index"
                :isfid="isfid"
              />
            </b-tab>
            <b-tab
              ref="HTSTab"
              title="HTS"
              @click="UpdateComponents()"
            >
              <ISFDetailHTS
                :key="index"
                :isfid="isfid"
                :customerid="isf.CustomerId"
              />
            </b-tab>
            <b-tab
              ref="TimelineTab"
              title="Timeline"
              @click="UpdateComponents()"
            >
              <ISFDetailTimeline
                :key="index"
                :isfid="isfid"
              />
            </b-tab>
            <b-tab
              ref="NotificationTab"
              title="Notifications"
              @click="UpdateComponents()"
            >
              <b-row>
                <b-col md="12">
                  <b-tabs lazy>
                    <b-tab
                      title="Notification"
                      @click="UpdateComponents()"
                    >
                      <ISFDetailNotification
                        :key="index"
                        :isfid="isfid"
                      />
                    </b-tab>
                    <b-tab
                      title="Response"
                      @click="UpdateComponents()"
                    >
                      <ISFDetailResponse
                        :key="index"
                        :isfid="isfid"
                      />
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>

</template>

<script>
import axios from '@axios'
import ISFDetailHeader from './ISFDetailHeader.vue'
import ISFDetailParty from './ISFDetailParties.vue'
import ISFDetailHBOL from './ISFDetailHBOL.vue'
import ISFDetailReference from './ISFDetailReference.vue'
import ISFDetailContainer from './ISFDetailContainer.vue'
import ISFDetailHTS from './ISFDetailHTS.vue'
import ISFDetailTimeline from './ISFDetailTimeline.vue'
import ISFDetailResponse from './ISFDetailResponse.vue'
import ISFDetailNotification from './ISFDetailNotification.vue'

export default {
  components: {
    ISFDetailHeader,
    ISFDetailParty,
    ISFDetailReference,
    ISFDetailHBOL,
    ISFDetailContainer,
    ISFDetailHTS,
    ISFDetailTimeline,
    ISFDetailResponse,
    ISFDetailNotification,
  },
  data() {
    return {
      index: 0,
      isfid: this.$route.params.isfId,
      isf: {
        CustomerId: null,
        ISFNumber: '',
        Status: '',
        BondActivity: '',
        BondType: '',
        ShipmentType: '',
      },
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    Promise.all([this.LoadingStart(), this.GetISF()])
      .then(() => {
        this.LoadingEnd()
      })
  },
  created() {
  },
  methods: {
    GetISF() {
      return axios.get(`/isf/${this.isfid}/detail/header`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.isf.CustomerId = respdata.customerId
          this.isf.ISFNumber = this.CheckNull(respdata.isfNumber) ? respdata.isfNumber : 'PENDING'
          this.isf.Status = respdata.status
          this.isf.BondActivity = respdata.bondActivity
          this.isf.BondType = respdata.bondType
          this.isf.ShipmentType = respdata.shipmentType
        })
    },
    UpdateComponents() {
      this.GetISF()
      this.index += 1
    },
    SendISF() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/isf/${this.isfid}/ace/send`).then(() => {
            this.LoadingEnd()
            this.isf.Status = 'Filed'
            this.DisplaySuccess('ISF has been Sent.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Cannot send ISF.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    DeleteISF() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/isf/${this.isfid}/delete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('ISF has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/abi/isf')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove ISF.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    sendDeleteISF() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/isf/${this.isfid}/ace/delete`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('ISF has been removed from CBP, check response tab for more detail.')
              .then(() => {
                // redirect
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove ISF.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
