<template>
  <div>
    <b-card>
      <AgGrid
        ref="ISFHTSGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'Create a New HTS'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewHTS()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New HTS</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateHTSModal"
      ref="CreateHTS"
      title="New HTS"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="NewHTSRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Manufacturer/Supplier"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Manufacturer/Supplier:</b></i></label>
                  <v-select
                    id="NewManufacturerId"
                    v-model="hts.MID"
                    :options="ManufacturerOptions"
                    label="name"
                    placeholder="Select the party"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="COO:"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>COO:</b></i></label>
                  <v-select
                    id="NewCOO"
                    v-model="hts.COO"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="NewHTS"
                rules="required|min:8|max:10|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>HTS(Number only):</b></i></label>
                  <b-form-input
                    id="HTS"
                    v-model="hts.HTS"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="HTS #"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewHTS()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateHTSModal"
      ref="UpdateHTS"
      title="Update HTS"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateHTSRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Manufacturer/Supplier"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Manufacturer/Supplier:</b></i></label>
                  <v-select
                    id="ManufacturerId"
                    v-model="hts.MID"
                    :options="ManufacturerOptions"
                    label="name"
                    placeholder="Select the party"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="COO:"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>COO:</b></i></label>
                  <v-select
                    id="COO"
                    v-model="hts.COO"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="HTS"
                rules="required|min:8|max:10|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>HTS(Number only):</b></i></label>
                  <b-form-input
                    id="HTS"
                    v-model="hts.HTS"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="HTS #"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateHTS()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIISFContainerBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'IsfHBOL',
  components: {
    AgGrid,
  },
  props: {
    isfid: { type: String, default: '' },
    customerid: { type: Number, default: 0 },
  },
  data() {
    return {
      required,
      ManufacturerOptions: [],
      CountryOptions: [],
      aggrid: [],
      hts: {
        HTSId: '',
        COO: '',
        HTS: '',
        MID: '',
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISFHTS')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'HTS', field: 'hts', minWidth: 100 },
      { headerName: 'COO', field: 'coo', minWidth: 100 },
      { headerName: 'MID', field: 'mid', minWidth: 100 },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFHTSGrid.saveState('ISFHTS') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFHTSGrid.saveState('ISFHTS') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFHTSGrid.saveState('ISFHTS') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFHTSGrid.saveState('ISFHTS') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(htsId) {
      this.ResetObject()
      this.$refs.UpdateHTS.show()
      this.LoadHTS(htsId)
    },
    DoubleClickOpenPopup(params) {
      this.ResetObject()
      this.$refs.UpdateHTS.show()
      this.LoadHTS(params.data.id)
    },
    CreateNewHTS() {
      this.ResetObject()
      this.$refs.CreateHTS.show()
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadLines(), this.LoadManufacturerList(), this.LoadCountryList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadManufacturerList() {
      return axios.get(`/abi/party/${this.customerid}/manufacturer`)
        .then(rowData => {
          this.ManufacturerOptions = rowData.data
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadLines() {
      return axios.get(`/isf/${this.isfid}/detail/hts/list`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ISFHTSGrid.ResetColumns()
        })
    },
    LoadHTS(HTSId) {
      axios.get(`/isf/${this.isfid}/detail/hts/${HTSId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response[0]
        this.hts.HTSId = respdata.id
        this.hts.HTS = respdata.hts
        this.hts.COO = this.CountryOptions.find(option => option.countryCode === respdata.coo)
        this.hts.MID = this.ManufacturerOptions.find(option => option.midNumber === respdata.mid)
        this.LoadingEnd()
      })
    },
    SaveNewHTS() {
      this.$refs.NewHTSRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFHTSModel = {
            HTS: this.hts.HTS,
            COO: this.hts.COO.countryCode,
            MID: this.hts.MID.midNumber,

          }
          axios.post(`/isf/${this.isfid}/detail/hts`, ISFHTSModel).then(() => {
            this.LoadingEnd()
            this.$refs.CreateHTS.hide()
            this.DisplaySuccess('HTS data has been saved.').then(() => {
              this.LoadLines()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateHTS.hide()
              this.DisplayError('New HTS cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New HTS cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateHTS() {
      this.$refs.UpdateHTSRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFHTSModel = {
            HTS: this.hts.HTS,
            COO: this.hts.COO.countryCode,
            MID: this.hts.MID.midNumber,

          }
          axios.put(`/isf/${this.isfid}/detail/hts/${this.hts.HTSId}`, ISFHTSModel).then(() => {
            this.LoadingEnd()
            this.$refs.UpdateHTS.hide()
            this.DisplaySuccess('HTS data has been updated.').then(() => {
              this.LoadLines()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.UpdateContainer.hide()
              this.DisplayError('HTS cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('HTS cannot be updated.', 'Please check data requirements.')
        }
      })
    },
    Delete(htsId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/isf/${this.isfid}/detail/hts/${htsId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('HTS has been removed.')
              .then(() => {
                // redirect
                this.LoadLines()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('HTS remove HBOL.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetObject() {
      this.hts = {
        HTSId: '',
        COO: '',
        HTS: '',
        MID: '',
      }
    },
  },
}
</script>
