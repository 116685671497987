<template>
  <div>
    <b-button-toolbar justify>
      <div>
        <slot name="lftbtn" />
      </div>

      <div>
        <b-button
          v-b-tooltip.hover="'Refresh'"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon mb-1"
          @click="LoadAll()"
        >
          <font-awesome-icon
            :icon="['fas', 'sync']"
            size="lg"
          />
        </b-button>&nbsp;
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          class="mb-1"
          @click="clearFilter()"
        ><font-awesome-icon
           :icon="['fas', 'filter']"
           class="mr-50"
           size="lg"
         />
          <span class="align-middle">Clear</span></b-button>
      </div>
    </b-button-toolbar>
    <ag-grid-vue
      :style="tblstyle"
      :grid-options="grid.gridOptions"
      class="ag-theme-alpine ag-grid-table"
      :column-defs="grid.columnDefs"
      :row-data="grid.rowData"
      :default-col-def="grid.defaultColDef"
      :animate-rows="true"
      :master-detail="grid.masterDetail"
      :detail-row-auto-height="true"
      :detail-cell-renderer-params="grid.detailCellRendererParams"
    />
  </div>
</template>
<script>
export default {
  props: {
    grid: { type: Object, default() { return {} } },
    tblstyle: { type: String, default: 'width:100%' },
  },
  methods: {
    LoadAll() {
      this.$emit('LoadAll')
    },
    clearFilter() {
      const allColumnIds = []
      this.grid.gridOptions.columnApi.getColumns().forEach(column => {
        allColumnIds.push(column.colId)
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < allColumnIds.length; i++) this.grid.gridOptions.api.destroyFilter(allColumnIds[i])
      })
    },
    ResetColumns() {
      const allColumnIds = []
      this.grid.gridOptions.columnApi.getColumns().forEach(column => {
        allColumnIds.push(column.colId)
      })
      this.grid.gridOptions.columnApi.autoSizeColumns(allColumnIds)
      this.restoreState(this.grid.Name)
    },
    saveState(name) {
      try {
        localStorage.setItem(`window.colState.${name}`, JSON.stringify(this.grid.gridOptions.columnApi.getColumnState()))
        localStorage.setItem(`window.groupState.${name}`, JSON.stringify(this.grid.gridOptions.columnApi.getColumnGroupState()))
        localStorage.setItem(`window.filterState.${name}`, JSON.stringify(this.grid.gridOptions.api.getFilterModel()))
      } catch (e) {
        // a
        // console.log(e)
      }
    },
    restoreState(name) {
      try {
        this.grid.gridOptions.columnApi.applyColumnState({ state: JSON.parse(localStorage.getItem(`window.colState.${name}`)), applyOrder: true })
        this.grid.gridOptions.columnApi.applyColumnState({ state: JSON.parse(localStorage.getItem(`window.groupState.${name}`)), applyOrder: true })
        this.grid.gridOptions.api.setFilterModel(JSON.parse(localStorage.getItem(`window.filterState.${name}`)))
      } catch (e) {
        // a
        // console.log(e)
      }
    },
  },
}
</script>
