var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"referenceRules",attrs:{"tag":"form"}},[_c('b-button-toolbar',{attrs:{"justify":""}},[_c('div'),_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Update in Database (Does not update in CBP system)'),expression:"'Update in Database (Does not update in CBP system)'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateReference()}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'save'],"size":"lg"}})],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ISF Filer Name","rules":"max:45|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("ISF Filer Name:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"ISFFilerName","state":errors.length > 0 ? false:null,"placeholder":"ISF Filer Name"},model:{value:(_vm.reference.ISFFilerName),callback:function ($$v) {_vm.$set(_vm.reference, "ISFFilerName", $$v)},expression:"reference.ISFFilerName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Entry Number","rules":("" + (_vm.shipmenttype == '06' ? 'max:45|required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.shipmenttype == '06' ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Entry Number:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"EntryNumber","state":errors.length > 0 ? false:null,"placeholder":"Entry Number"},model:{value:(_vm.reference.EntryNumber),callback:function ($$v) {_vm.$set(_vm.reference, "EntryNumber", $$v)},expression:"reference.EntryNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Carnet","rules":"max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Carnet:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"Carnet","state":errors.length > 0 ? false:null,"placeholder":"Carnet"},model:{value:(_vm.reference.Carnet),callback:function ($$v) {_vm.$set(_vm.reference, "Carnet", $$v)},expression:"reference.Carnet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Surety Code","rules":("" + (_vm.bondactivity == '16' && _vm.bondtype == '9'? 'required|max:3|min:3' : 'max:3|min:3'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.bondactivity == '16' && _vm.bondtype == '9' ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Surety Code:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"SuretyCode","state":errors.length > 0 ? false:null,"placeholder":"Surety Code"},model:{value:(_vm.reference.SuretyCode),callback:function ($$v) {_vm.$set(_vm.reference, "SuretyCode", $$v)},expression:"reference.SuretyCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Reference #","rules":("" + (_vm.bondactivity == 16 && _vm.bondtype == 9 ? 'required|max:45|min:5' : 'max:45|min:5'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.bondactivity == 16 && _vm.bondtype == 9 ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Bond Reference # (Not same as Bond #):")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"BondReference","state":errors.length > 0 ? false:null,"placeholder":"Bond Reference"},model:{value:(_vm.reference.BondReference),callback:function ($$v) {_vm.$set(_vm.reference, "BondReference", $$v)},expression:"reference.BondReference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"User Defined","rules":"max:45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("User Defined:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"UserDefined","state":errors.length > 0 ? false:null,"placeholder":"UserDefined"},model:{value:(_vm.reference.UserDefined),callback:function ($$v) {_vm.$set(_vm.reference, "UserDefined", $$v)},expression:"reference.UserDefined"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }