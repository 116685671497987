<template>
  <div>
    <b-card>
      <AgGrid
        ref="ISFContainersGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'Create a New Container'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewContainer()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New Container</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateContainerModal"
      ref="CreateContainer"
      title="New Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="NewContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Type:</b></i></label>
                  <v-select
                    id="NewEQPType"
                    v-model="container.EQPType"
                    :options="ContainerTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CBP Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>CBP Type:</b></i></label>
                  <v-select
                    id="NewEQPDescriptionCode"
                    v-model="container.EQPDescriptionCode"
                    :options="ContainerDescTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Prefix"
                rules="required|min:4|max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Prefix:</b></i></label>
                  <b-form-input
                    id="NewEQPPrefix"
                    v-model="container.EQPPrefix"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="XXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Number"
                rules="required|min:6|max:15|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Continer Number:</b></i></label>
                  <b-form-input
                    id="NewEQPNumber"
                    v-model="container.EQPNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="XXXXXXXXXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CheckDigit"
                rules="required|min:1|max:1|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Check Digit:</b></i></label>
                  <b-form-input
                    id="NewEQPCheckDigit"
                    v-model="container.EQPCheckDigit"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="X"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateContainerModal"
      ref="UpdateContainer"
      title="Update Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Type:</b></i></label>
                  <v-select
                    id="NewEQPType"
                    v-model="container.EQPType"
                    :options="ContainerTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CBP Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>CBP Type:</b></i></label>
                  <v-select
                    id="NewEQPDescriptionCode"
                    v-model="container.EQPDescriptionCode"
                    :options="ContainerDescTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Prefix"
                rules="required|min:4|max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Prefix:</b></i></label>
                  <b-form-input
                    id="EQPPrefix"
                    v-model="container.EQPPrefix"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="XXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Number"
                rules="required|min:6|max:15|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Continer Number:</b></i></label>
                  <b-form-input
                    id="EQPNumber"
                    v-model="container.EQPNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="XXXXXXXXXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CheckDigit"
                rules="required|min:1|max:1|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Check Digit:</b></i></label>
                  <b-form-input
                    id="EQPCheckDigit"
                    v-model="container.EQPCheckDigit"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="X"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIISFContainerBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'IsfHBOL',
  components: {
    AgGrid,
  },
  props: {
    isfid: { type: String, default: '' },
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ContainerTypeOption: [
        { text: '20G0 (GENERAL PURPOSE CONT.)', value: '20G0' },
        { text: '20G1 (GENERAL PURPOSE CONT.)', value: '20G1' },
        { text: '20H0 (INSULATED CONTAINER)', value: '20H0' },
        { text: '20P1 (FLAT (FIXED ENDS))', value: '20P1' },
        { text: '20T3 (TANK CONTAINER)', value: '20T3' },
        { text: '20T4 (TANK CONTAINER)', value: '20T4' },
        { text: '20T5 (TANK CONTAINER)', value: '20T5' },
        { text: '20T6 (TANK CONTAINER)', value: '20T6' },
        { text: '20T7 (TANK CONTAINER)', value: '20T7' },
        { text: '20T8 (TANK CONTAINER)', value: '20T8' },
        { text: '20T0 (TANK CONTAINER)', value: '20T0' },
        { text: '20T1 (TANK CONTAINER)', value: '20T1' },
        { text: '20T2 (TANK CONTAINER)', value: '20T2' },
        { text: '22B0 (BULK CONTAINER)', value: '22B0' },
        { text: '22G0 (GENERAL PURPOSE CONT.)', value: '22G0' },
        { text: '22G1 (GENERAL PURPOSE CONT.)', value: '22G1' },
        { text: '22H0 (INSULATED CONTAINER)', value: '22H0' },
        { text: '22P3 (FLAT (COLLAPSIBLE))', value: '22P3' },
        { text: '22P8 (FLAT (COLL.FLUSH FOLDING))', value: '22P8' },
        { text: '22P9 (FLAT (COLLAPSIBLE))', value: '22P9' },
        { text: '22P1 (FLAT (FIXED ENDS))', value: '22P1' },
        { text: '22P7 (FLAT (GENSET CARRIER))', value: '22P7' },
        { text: '22R9 (REEFER CONT.(NO FOOD))', value: '22R9' },
        { text: '22R7 (BUILT-IN GEN. F. POWER SPLY OF REEF)', value: '22R7' },
        { text: '22R1 (REEFER CONTAINER)', value: '22R1' },
        { text: '22S1 (NAMED CARGO CONTAINER)', value: '22S1' },
        { text: '22T3 (TANK CONTAINER)', value: '22T3' },
        { text: '22T4 (TANK CONTAINER)', value: '22T4' },
        { text: '22T5 (TANK CONTAINER)', value: '22T5' },
        { text: '22T6 (TANK CONTAINER)', value: '22T6' },
        { text: '22T7 (TANK CONTAINER)', value: '22T7' },
        { text: '22T8 (TANK CONTAINER)', value: '22T8' },
        { text: '22T0 (TANK CONTAINER)', value: '22T0' },
        { text: '22T1 (TANK CONTAINER)', value: '22T1' },
        { text: '22T2 (TANK CONTAINER)', value: '22T2' },
        { text: '22U6 (HARDTOP CONTAINER)', value: '22U6' },
        { text: '22U1 (OPEN TOP CONTAINER)', value: '22U1' },
        { text: '22V0 (VENTILATED CONTAINER)', value: '22V0' },
        { text: '22V2 (VENTILATED CONTAINER)', value: '22V2' },
        { text: '22V3 (VENTILATED CONTAINER)', value: '22V3' },
        { text: '25G0 (GP-CONTAINER OVER-HEIGHT)', value: '25G0' },
        { text: '26G0 (GP-CONTAINER OVER-HEIGHT)', value: '26G0' },
        { text: '26H0 (INSULATED CONTAINER)', value: '26H0' },
        { text: '28T8 (TANK FOR GAS)', value: '28T8' },
        { text: '28U1 (OPEN TOP (HALF HEIGHT))', value: '28U1' },
        { text: '28V0 (VE-HALF-HEIGHT =1448 MM HEIGHT)', value: '28V0' },
        { text: '29P0 (PLATFORM)', value: '29P0' },
        { text: '2EG0 (HIGH CUBE CONT. (WIDTH 2.5M))', value: '2EG0' },
        { text: '42G0 (GENERAL PURPOSE CONT.)', value: '42G0' },
        { text: '42G1 (GENERAL PURPOSE CONT.)', value: '42G1' },
        { text: '42H0 (INSULATED CONTAINER)', value: '42H0' },
        { text: '42P3 (FLAT (COLLAPSIBLE))', value: '42P3' },
        { text: '42P8 (FLAT (COLL.FLUSH FOLDING))', value: '42P8' },
        { text: '42P9 (FLAT (COLLAPSIBLE))', value: '42P9' },
        { text: '42P1 (FLAT (FIXED ENDS))', value: '42P1' },
        { text: '42P6 (FLAT SPACE SAVER)', value: '42P6' },
        { text: '42R9 (REEFER CONT.(NO FOOD))', value: '42R9' },
        { text: '42R3 (REEFER CONT.(DIESEL GEN.))', value: '42R3' },
        { text: '42R1 (REEFER CONTAINER)', value: '42R1' },
        { text: '42S1 (NAMED CARGO CONTAINER)', value: '42S1' },
        { text: '42T5 (TANK CONTAINER)', value: '42T5' },
        { text: '42T6 (TANK CONTAINER)', value: '42T6' },
        { text: '42T8 (TANK CONTAINER)', value: '42T8' },
        { text: '42T2 (TANK CONTAINER)', value: '42T2' },
        { text: '42U6 (HARDTOP CONTAINER)', value: '42U6' },
        { text: '42U1 (OPEN TOP CONTAINER)', value: '42U1' },
        { text: '45B3 (BULK CONTAINER)', value: '45B3' },
        { text: '45G0 (HIGH CUBE CONTAINER)', value: '45G0' },
        { text: '45G1 (HIGH CUBE CONT.)', value: '45G1' },
        { text: '45P3 (FLAT (COLLAPSIBLE))', value: '45P3' },
        { text: '45P8 (FLAT (COLL.FLUSH FOLDING))', value: '45P8' },
        { text: '45R9 (REEFER CONT.(NO FOOD))', value: '45R9' },
        { text: '45R1 (REEFER HIGHCUBE CONTAINER)', value: '45R1' },
        { text: '45U1 (OPEN TOP CONTAINER)', value: '45U1' },
        { text: '45U6 (HIGH CUBE HARDTOP CONT.)', value: '45U6' },
        { text: '46H0 (INSULATED CONTAINER)', value: '46H0' },
        { text: '48T8 (TANK FOR GAS)', value: '48T8' },
        { text: '49P0 (PLATFORM)', value: '49P0' },
        { text: '4CG0 (GP CONTAINER (WIDTH 2.5 M))', value: '4CG0' },
        { text: 'L0G1 (HIGH CUBE CONT.)', value: 'L0G1' },
        { text: 'L2G1 (HIGH CUBE CONT.)', value: 'L2G1' },
        { text: 'L5G1 (HIGH CUBE CONT.)', value: 'L5G1' },
      ],
      ContainerDescTypeOption: [
        { text: '20 ft IL Container (Open Top) (20)', value: '20' },
        { text: '20 ft. IL Container (Closed Top) (2B)', value: '2B' },
        { text: 'Control Unit (2D)', value: '2D' },
        { text: 'Helper Unit (2E)', value: '2E' },
        { text: 'Road railer (2F)', value: '2F' },
        { text: '40 ft. IL Container (Open Top) (40)', value: '40' },
        { text: '40 ft. IL Container (Closed Top) (4B)', value: '4B' },
        { text: 'Closed Container (AC)', value: 'AC' },
        { text: 'Air Freight (Break Bulk) (AF)', value: 'AF' },
        { text: 'Container, Aluminum (AL)', value: 'AL' },
        { text: 'Aircraft (AP)', value: 'AP' },
        { text: 'Closed Container (Controlled Temperature) (AT)', value: 'AT' },
        { text: 'Covered Barge (BC)', value: 'BC' },
        { text: 'Bilevel Railcar Fully Open (BE)', value: 'BE' },
        { text: 'Bilevel Railcar Fully Enclosed (BF)', value: 'BF' },
        { text: 'Bogie (BG)', value: 'BG' },
        { text: 'Bilevel Railcar Screened with Roof (BH)', value: 'BH' },
        { text: 'Bilevel Railcar Screened, No Roof (BJ)', value: 'BJ' },
        { text: 'Container, Bulk (BK)', value: 'BK' },
        { text: 'Barge, Open (BO)', value: 'BO' },
        { text: 'Barge (BR)', value: 'BR' },
        { text: 'Boxcar (BX)', value: 'BX' },
        { text: 'Caboose (CA)', value: 'CA' },
        { text: 'Chassie, Goose neck (CB)', value: 'CB' },
        { text: 'Container Resting on a Chassis (CC)', value: 'CC' },
        { text: 'Container with Bag Hangers (CD)', value: 'CD' },
        { text: 'Container, Tank (Gas) (CG)', value: 'CG' },
        { text: 'Chassis (CH)', value: 'CH' },
        { text: 'Container, Insulated (CI)', value: 'CI' },
        { text: 'Container, Insulated/Ventilated (CJ)', value: 'CJ' },
        { text: 'Container, Heated/Insulated/ Ventilated (CK)', value: 'CK' },
        { text: 'Container (Closed Top Length Unspecified) (CL)', value: 'CL' },
        { text: 'Container, Open-Sided (CM)', value: 'CM' },
        { text: 'Container (CN)', value: 'CN' },
        { text: 'Coil Car Open (CP)', value: 'CP' },
        { text: 'Container, Tank (Food Grade - Liquid) (CQ)', value: 'CQ' },
        { text: 'Coil Car Covered (CR)', value: 'CR' },
        { text: 'Container - Low Side Open Top (CS)', value: 'CS' },
        { text: 'Container - High Side Open top (CT)', value: 'CT' },
        { text: 'Container (Open Top - Length Unspecified) (CU)', value: 'CU' },
        { text: 'Closed Van (CV)', value: 'CV' },
        { text: 'Container, Tank (Chemicals) (CW)', value: 'CW' },
        { text: 'Container, Tank (CX)', value: 'CX' },
        { text: 'Refrigerated Container (CZ)', value: 'CZ' },
        { text: 'Double Drop Tailer - A flatbed with two drop decks (DD)', value: 'DD' },
        { text: 'Container with Flush Doors (DF)', value: 'DF' },
        { text: 'Drop Back Trailer (DT)', value: 'DT' },
        { text: 'Boxcar, Damage Free Equipped (DX)', value: 'DX' },
        { text: 'End of Train Device (ET)', value: 'ET' },
        { text: 'Flat Bed Trailer with Headboards (FH)', value: 'FH' },
        { text: 'Flat Bed Trailer with No Headboards (FN)', value: 'FN' },
        { text: 'Flatcar with Pedestal (FP)', value: 'FP' },
        { text: 'Flat Bed Trailer - Removable Sides (FR)', value: 'FR' },
        { text: 'Container with Floor Securing  Rings (FS)', value: 'FS' },
        { text: 'Flat Bed Trailer (FT)', value: 'FT' },
        { text: 'Boxcar Cushion Under Frame of (FX)', value: 'FX' },
        { text: 'Generator Set (GS)', value: 'GS' },
        { text: 'Container with Hangar Bars (HB)', value: 'HB' },
        { text: 'Hopper Car (Covered) (HC)', value: 'HC' },
        { text: 'Hopper Car (Open) (HO)', value: 'HO' },
        { text: 'Hopper Car (Covered:  Pneumatic Discharge) (HP)', value: 'HP' },
        { text: 'Head of Train Device (HT)', value: 'HT' },
        { text: 'High Cube Van (HV)', value: 'HV' },
        { text: 'Hydrant Cart  (HY)', value: 'HY' },
        { text: 'Idler Car (ID)', value: 'ID' },
        { text: 'Boxcar (Insulated) (IX)', value: 'IX' },
        { text: 'Locomotive (LO)', value: 'LO' },
        { text: 'Half Height Flat Rack (LS)', value: 'LS' },
        { text: 'Load/Unload Device on Equipment (LU)', value: 'LU' },
        { text: 'Non-containerized or No Equipment (NC)', value: 'NC' },
        { text: 'Boxcar (Interior Bulkheads) (NX)', value: 'NX' },
        { text: 'Ocean Vessel (OB)', value: 'OB' },
        { text: 'Open Top Flat Bed Trailer (OT)', value: 'OT' },
        { text: 'Open Top Van (OV)', value: 'OV' },
        { text: 'Container Platform (PL)', value: 'PL' },
        { text: 'Power Pack (PP)', value: 'PP' },
        { text: 'Protected Trailer (PT)', value: 'PT' },
        { text: 'Pick-up Truck (PU)', value: 'PU' },
        { text: 'Fixed Rack (RA)', value: 'RA' },
        { text: 'Refrigerated (Reefer) Car (RC)', value: 'RC' },
        { text: 'Fixed Rack, Double Drop Trailer (RD)', value: 'RD' },
        { text: 'Flat Car (End Bulkheads) (RE)', value: 'RE' },
        { text: 'Flat Car (RF)', value: 'RF' },
        { text: 'Gondola Covered (RG)', value: 'RG' },
        { text: 'Gondola Car (Covered – Interior Bulkheads) (RI)', value: 'RI' },
        { text: 'Gondola Car (Open) (RO)', value: 'RO' },
        { text: 'Rail Car (RR)', value: 'RR' },
        { text: 'Fixed Rack Single Drop Trailer (RS)', value: 'RS' },
        { text: 'Controlled Temperature Trailer (Reefer) (RT)', value: 'RT' },
        { text: 'Saddle (SA)', value: 'SA' },
        { text: 'Service Car (SC)', value: 'SC' },
        { text: 'Single Drop Trailer (SD)', value: 'SD' },
        { text: 'Stack Car (SK)', value: 'SK' },
        { text: 'Container, Steel (SL)', value: 'SL' },
        { text: 'Stak-Rak (SR)', value: 'SR' },
        { text: 'Container with Smooth Sides (SS)', value: 'SS' },
        { text: 'Removable Side Trailer (ST)', value: 'ST' },
        { text: 'Van (SV)', value: 'SV' },
        { text: 'Trailer, Heated/Insulated/Ventilated (TA)', value: 'TA' },
        { text: 'Trailer, Boat (TB)', value: 'TB' },
        { text: 'Trailer, Car (TC)', value: 'TC' },
        { text: 'Trailer, Dry Freight (TF)', value: 'TF' },
        { text: 'Trailer, Tank (Gas) (TG)', value: 'TG' },
        { text: 'Truck, Open Top High Side (TH)', value: 'TH' },
        { text: 'Trailer, Insulated (TI)', value: 'TI' },
        { text: 'Trailer, Tank (Chemicals) (TJ)', value: 'TJ' },
        { text: 'Trailer, Tank (Food Grade Liquid) (TK)', value: 'TK' },
        { text: 'Trailer (Not Otherwise Specified) (TL)', value: 'TL' },
        { text: 'Trailer, Insulated/Ventilated (TM)', value: 'TM' },
        { text: 'Tank Car (TN)', value: 'TN' },
        { text: 'Truck, Open Top (TO)', value: 'TO' },
        { text: 'Trailer, Pneumatic (TP)', value: 'TP' },
        { text: 'Trailer, Electric Heat (TQ)', value: 'TQ' },
        { text: 'Tractor (TR)', value: 'TR' },
        { text: 'Telescoping Trailer (TT)', value: 'TT' },
        { text: 'Truck, Open Top Low Side (TU)', value: 'TU' },
        { text: 'Truck, Van (TV)', value: 'TV' },
        { text: 'Trailer, Refrigerated (TW)', value: 'TW' },
        { text: 'Trilevel Railcar 20 Feet (UA)', value: 'UA' },
        { text: 'Trilevel Railcar Screened, Fully Enclosed (UB)', value: 'UB' },
        { text: 'Trilevel Railcar Screened, With Roof (UC)', value: 'UC' },
        { text: 'Trilevel Railcar Screened, No Roof (UD)', value: 'UD' },
        { text: 'Trilevel Railcar Screened, With Door, No Roof (UE)', value: 'UE' },
        { text: 'Unit Load Device (ULD) (UL)', value: 'UL' },
        { text: 'Container, Upgraded (UP)', value: 'UP' },
        { text: 'Container, Vented (VA)', value: 'VA' },
        { text: 'Vessel, Ocean (VE)', value: 'VE' },
        { text: 'Vessel, Lake (VL)', value: 'VL' },
        { text: 'Vessel, Ocean, Roll on-Roll off (VR)', value: 'VR' },
        { text: 'Vessel, Ocean, Lash (VS)', value: 'VS' },
        { text: 'Vessel, Ocean, Containership (VT)', value: 'VT' },
        { text: 'Container with Wavy or Ripple Sides (WR)', value: 'WR' },
        { text: 'Railroad Maintenance of Way Car (WY)', value: 'WY' },

      ],
      container: {
        ContainerId: '',
        EQPDescriptionCode: '',
        EQPPrefix: '',
        EQPNumber: '',
        EQPCheckDigit: '',
        EQPType: '',
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISFContainers')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Type', field: 'eqpType', minWidth: 100 },
      { headerName: 'CBP Type', field: 'eqpDescriptionCode', minWidth: 100 },
      { headerName: 'Prefix', field: 'eqpPrefix', minWidth: 100 },
      { headerName: 'Container Number', field: 'eqpNumber', minWidth: 125 },
      { headerName: 'Check Digit', field: 'eqpCheckDigit', minWidth: 100 },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFContainersGrid.saveState('ISFContainers') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFContainersGrid.saveState('ISFContainers') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFContainersGrid.saveState('ISFContainers') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFContainersGrid.saveState('ISFContainers') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(hbolId) {
      this.ResetObject()
      this.$refs.UpdateContainer.show()
      this.LoadContainer(hbolId)
    },
    DoubleClickOpenPopup(params) {
      this.ResetObject()
      this.$refs.UpdateContainer.show()
      this.LoadContainer(params.data.id)
    },

    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadLines()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadLines() {
      return axios.get(`/isf/${this.isfid}/detail/container/list`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ISFContainersGrid.ResetColumns()
        })
    },
    LoadContainer(ContainerId) {
      axios.get(`/isf/${this.isfid}/detail/container/${ContainerId}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response[0]
        this.container.ContainerId = respdata.id
        this.container.EQPPrefix = respdata.eqpPrefix
        this.container.EQPNumber = respdata.eqpNumber
        this.container.EQPCheckDigit = respdata.eqpCheckDigit
        this.container.EQPDescriptionCode = this.ContainerDescTypeOption.find(option => option.value === respdata.eqpDescriptionCode)
        this.container.EQPType = this.ContainerTypeOption.find(option => option.value === respdata.eqpType)
        this.LoadingEnd()
      })
    },
    CreateNewContainer() {
      this.ResetObject()
      this.$refs.CreateContainer.show()
    },
    SaveNewContainer() {
      this.$refs.NewContainerRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFContainerModel = {
            EQPPrefix: this.container.EQPPrefix,
            EQPNumber: this.container.EQPNumber,
            EQPCheckDigit: this.container.EQPCheckDigit,
            EQPDescriptionCode: this.container.EQPDescriptionCode.value,
            EQPType: this.container.EQPType.value,
          }
          axios.post(`/isf/${this.isfid}/detail/container`, ISFContainerModel).then(() => {
            this.LoadingEnd()
            this.$refs.CreateContainer.hide()
            this.DisplaySuccess('Container data has been saved.').then(() => {
              this.LoadLines()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateContainer.hide()
              this.DisplayError('New Container cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New Container cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateContainer() {
      this.$refs.UpdateContainerRules.validate().then(success => {
        if (success) {
          this.LoadingStart()
          const ISFContainerModel = {
            EQPPrefix: this.container.EQPPrefix,
            EQPNumber: this.container.EQPNumber,
            EQPCheckDigit: this.container.EQPCheckDigit,
            EQPDescriptionCode: this.container.EQPDescriptionCode.value,
            EQPType: this.container.EQPType.value,

          }
          axios.put(`/isf/${this.isfid}/detail/container/${this.container.ContainerId}`, ISFContainerModel).then(() => {
            this.LoadingEnd()
            this.$refs.UpdateContainer.hide()
            this.DisplaySuccess('Container data has been updated.').then(() => {
              this.LoadLines()
            })
          })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.UpdateContainer.hide()
              this.DisplayError('Container cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Container cannot be updated.', 'Please check data requirements.')
        }
      })
    },
    Delete(containerId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/isf/${this.isfid}/detail/container/${containerId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Container has been removed.')
              .then(() => {
                // redirect
                this.LoadLines()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Container cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetObject() {
      this.container = {
        ContainerId: '',
        EQPDescriptionCode: '',
        EQPPrefix: '',
        EQPNumber: '',
        EQPCheckDigit: '',
        EQPType: '',
      }
    },
  },
}
</script>
