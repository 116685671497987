import Vue from 'vue'

export default Vue.extend({
  data() {
    return {}
  },
  beforeMount() { },
  mounted() { },
  methods: {
    Clickhandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Open(returnID)
    },
    Deletehandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Delete(returnID)
    },
  },
  template: `
        <span>
            <b-button
            v-b-tooltip.hover="'View'"
            size="sm" 
            @click="Clickhandler()" 
            variant="primary" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'eye']"
              size="lg"
            />
            </b-button>
            <b-button
            v-b-tooltip.hover="'Delete'"
            size="sm" 
            @click="Deletehandler()" 
            variant="danger" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
            </b-button>
        </span>
    `,
})
