var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"headerRules",attrs:{"tag":"form"}},[_c('b-button-toolbar',{attrs:{"justify":""}},[_c('div',[_c('b-form-checkbox',{model:{value:(_vm.header.IsActive),callback:function ($$v) {_vm.$set(_vm.header, "IsActive", $$v)},expression:"header.IsActive"}},[_vm._v(" Active ")])],1),_c('div',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Update in Database (Does not update in CBP system)'),expression:"'Update in Database (Does not update in CBP system)'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateHeader()}}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'save'],"size":"lg"}})],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ISF Action","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("ISF Action:")])])]),_c('v-select',{attrs:{"id":"ISFActionOption","options":_vm.ISFActionOption,"label":"text","placeholder":"Select the action"},model:{value:(_vm.header.ActionType),callback:function ($$v) {_vm.$set(_vm.header, "ActionType", $$v)},expression:"header.ActionType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Action Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Action Reason:")])])]),_c('v-select',{attrs:{"id":"ISFActionReasonOptions","options":_vm.ISFActionReasonOptions,"label":"text","placeholder":"Select the reason"},model:{value:(_vm.header.ActionReason),callback:function ($$v) {_vm.$set(_vm.header, "ActionReason", $$v)},expression:"header.ActionReason"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Customer:")])])]),_c('v-select',{attrs:{"id":"CustomerOption","options":_vm.CustomerOptions,"label":"name","placeholder":"Select a Customer"},model:{value:(_vm.header.CustomerId),callback:function ($$v) {_vm.$set(_vm.header, "CustomerId", $$v)},expression:"header.CustomerId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"ISF Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("ISF Type:")])])]),_c('v-select',{attrs:{"id":"ISFSubmissionOptions","options":_vm.ISFSubmissionOptions,"label":"text","placeholder":"Select a Type"},model:{value:(_vm.header.SubmissionType),callback:function ($$v) {_vm.$set(_vm.header, "SubmissionType", $$v)},expression:"header.SubmissionType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Shipment Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Shipment Type:")])])]),_c('v-select',{attrs:{"id":"ShipmentTypeOptions","options":_vm.ShipmentTypeOptions,"label":"text","placeholder":"Select a Shipment Type"},model:{value:(_vm.header.ShipmentType),callback:function ($$v) {_vm.$set(_vm.header, "ShipmentType", $$v)},expression:"header.ShipmentType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Shipment Sub-Type","rules":("" + (_vm.CheckNull(_vm.header.ShipmentType) ? _vm.header.ShipmentType.value == '11' ? 'required' : '' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.CheckNull(_vm.header.ShipmentType) ? _vm.header.ShipmentType.value == '11' ? 'required' : '' : ''))},[_c('i',[_c('b',[_vm._v("Shipment Sub-Type:")])])]),_c('v-select',{attrs:{"id":"ShipmentSubTypeOptions","options":_vm.ShipmentSubTypeOptions,"label":"text","placeholder":"Select a Type"},model:{value:(_vm.header.SubShipmentType),callback:function ($$v) {_vm.$set(_vm.header, "SubShipmentType", $$v)},expression:"header.SubShipmentType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"SCAC","rules":"min:4|max:4|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("SCAC:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"SCAC","state":errors.length > 0 ? false:null,"placeholder":"SCAC"},model:{value:(_vm.header.SCAC),callback:function ($$v) {_vm.$set(_vm.header, "SCAC", $$v)},expression:"header.SCAC"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"MBOL #","rules":"max:50|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("MBOL #:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"MBOL","state":errors.length > 0 ? false:null,"placeholder":"MBOL"},model:{value:(_vm.header.MBOL),callback:function ($$v) {_vm.$set(_vm.header, "MBOL", $$v)},expression:"header.MBOL"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"MOT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("MOT:")])])]),_c('v-select',{attrs:{"id":"MOTOptions","options":_vm.MOTOptions,"label":"text","placeholder":"Select a MOT"},model:{value:(_vm.header.MOT),callback:function ($$v) {_vm.$set(_vm.header, "MOT", $$v)},expression:"header.MOT"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Qty","rules":"required|min_value:1|max_value:999999999|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Estimated Qty:")])])]),_c('b-form-input',{attrs:{"id":"Qty","type":"number","min":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.header.EstimatedQty),callback:function ($$v) {_vm.$set(_vm.header, "EstimatedQty", $$v)},expression:"header.EstimatedQty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"UOM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("UOM:")])])]),_c('v-select',{attrs:{"id":"UOM","options":_vm.UOMOptions,"label":"codeName","placeholder":"Select the UOM"},model:{value:(_vm.header.UOM),callback:function ($$v) {_vm.$set(_vm.header, "UOM", $$v)},expression:"header.UOM"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Estimated Weight","rules":"required|min_value:1|max_value:999999999|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Estimated Weight (KG):")])])]),_c('b-form-input',{attrs:{"id":"EstimatedWeight","type":"number","min":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.header.EstimatedWeight),callback:function ($$v) {_vm.$set(_vm.header, "EstimatedWeight", $$v)},expression:"header.EstimatedWeight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Estimated Value","rules":"required|min_value:1|max_value:999999999|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Estimated Value:")])])]),_c('b-form-input',{attrs:{"id":"EstimatedValue","type":"number","min":"1","state":errors.length > 0 ? false:null},model:{value:(_vm.header.EstimatedValue),callback:function ($$v) {_vm.$set(_vm.header, "EstimatedValue", $$v)},expression:"header.EstimatedValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Bond ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Holder","rules":("" + (_vm.CheckNull(_vm.header.ShipmentType) ?_vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required|max:12' : '' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.CheckNull(_vm.header.ShipmentType) ?_vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required' : '' : ''))},[_c('i',[_c('b',[_vm._v("Bond Holder ID:")])])]),_c('b-form-input',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"id":"BondHolder","state":errors.length > 0 ? false:null,"placeholder":"BondHolder"},model:{value:(_vm.header.BondHolder),callback:function ($$v) {_vm.$set(_vm.header, "BondHolder", $$v)},expression:"header.BondHolder"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Type","rules":("" + (_vm.CheckNull(_vm.header.ShipmentType) ? _vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required' : '' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.CheckNull(_vm.header.ShipmentType) ?_vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required' : '' : ''))},[_c('i',[_c('b',[_vm._v("Bond Type:")])])]),_c('v-select',{attrs:{"id":"BondTypeOptions","options":_vm.BondTypeOptions,"label":"text","placeholder":"Select a type"},model:{value:(_vm.header.BondType),callback:function ($$v) {_vm.$set(_vm.header, "BondType", $$v)},expression:"header.BondType"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Bond Activity","rules":("" + (_vm.CheckNull(_vm.header.ShipmentType) ?_vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required' : '' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.CheckNull(_vm.header.ShipmentType) ?_vm.header.ShipmentType.value == '01' || _vm.header.ShipmentType.value == '02' ||_vm.header.ShipmentType.value == '07' || _vm.header.ShipmentType.value == '08' || _vm.header.ShipmentType.value == '10' ? 'required' : '' : ''))},[_c('i',[_c('b',[_vm._v("Bond Activity Type:")])])]),_c('v-select',{attrs:{"id":"BondActivityOptions","options":_vm.BondActivityOptions,"label":"text","placeholder":"Select a type"},model:{value:(_vm.header.BondActivity),callback:function ($$v) {_vm.$set(_vm.header, "BondActivity", $$v)},expression:"header.BondActivity"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" Foreign reamin on board ")])]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Port of Unlading","rules":("" + (_vm.header.SubmissionType.value == '2' || _vm.header.SubmissionType.value == '4' ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.header.SubmissionType.value == '2' || _vm.header.SubmissionType.value == '4' ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Port of Unlading (Foreign):")])])]),_c('v-select',{attrs:{"id":"POU","options":_vm.ForeignPortsOptions,"label":"foreignPOLName","placeholder":"Select a port"},model:{value:(_vm.frob.POU),callback:function ($$v) {_vm.$set(_vm.frob, "POU", $$v)},expression:"frob.POU"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Place of Delivery","rules":("" + (_vm.header.SubmissionType.value == '2' || _vm.header.SubmissionType.value == '4' ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{class:("" + (_vm.header.SubmissionType.value == '2' || _vm.header.SubmissionType.value == '4' ? 'required' : ''))},[_c('i',[_c('b',[_vm._v("Place of Delivery (Foreign):")])])]),_c('v-select',{attrs:{"id":"POD","options":_vm.ForeignPortsOptions,"label":"foreignPOLName","placeholder":"Select a port"},model:{value:(_vm.frob.POD),callback:function ($$v) {_vm.$set(_vm.frob, "POD", $$v)},expression:"frob.POD"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text"},[_vm._v(" "+_vm._s('Last Updated: ' + _vm.header.LastUpdatedOn)+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }