<template>
  <div>
    <AgGridNoEdits
      ref="ISFNotificationGrid"
      :grid="aggrid"
      @LoadAll="LoadAll"
    />
  </div>
</template>

<script>
import axios from '@axios'
import AgGridNoEdits from '@/layouts/components/AgGridNoEdits.vue'

export default {
  name: 'IsfNotification',
  components: {
    AgGridNoEdits,
  },
  props: {
    isfid: { type: String, default: '' },
  },
  data() {
    return {
      aggrid: [],

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ISFNotification')
    this.aggrid.columnDefs = [
      { headerName: 'Bill #', field: 'billNumber', minWidth: 155 },
      {
        headerName: 'Disposition', field: 'dispositionCode', minWidth: 75, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Description', field: 'remarks', minWidth: 175, enableRowGroup: false, enablePivot: false,
      },
      {
        headerName: 'Added Date', field: 'addedDate', minWidth: 100,
      },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ISFNotificationGrid.saveState('ISFNotification') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ISFNotificationGrid.saveState('ISFNotification') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ISFNotificationGrid.saveState('ISFNotification') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ISFNotificationGrid.saveState('ISFNotification') }
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadResponse()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadResponse() {
      return axios.get(`/isf/${this.isfid}/detail/notification`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ISFNotificationGrid.ResetColumns()
        })
    },
  },
}
</script>
