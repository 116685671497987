<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="referenceRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div />
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateReference()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="ISF Filer Name"
                  rules="max:45|required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>ISF Filer Name:</b></i></label>
                    <b-form-input
                      id="ISFFilerName"
                      v-model="reference.ISFFilerName"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="ISF Filer Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Entry Number"
                  :rules="`${shipmenttype == '06' ? 'max:45|required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${shipmenttype == '06' ? 'required' : ''}`"><i><b>Entry Number:</b></i></label>
                    <b-form-input
                      id="EntryNumber"
                      v-model="reference.EntryNumber"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Entry Number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Carnet"
                  rules="max:45"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>Carnet:</b></i></label>
                    <b-form-input
                      id="Carnet"
                      v-model="reference.Carnet"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Carnet"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Surety Code"
                  :rules="`${ bondactivity == '16' && bondtype == '9'? 'required|max:3|min:3' : 'max:3|min:3'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${ bondactivity == '16' && bondtype == '9' ? 'required' : ''}`"><i><b>Surety Code:</b></i></label>
                    <b-form-input
                      id="SuretyCode"
                      v-model="reference.SuretyCode"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Surety Code"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Reference #"
                  :rules="`${ bondactivity == 16 && bondtype == 9 ? 'required|max:45|min:5' : 'max:45|min:5'}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${ bondactivity == 16 && bondtype == 9 ? 'required' : ''}`"><i><b>Bond Reference # (Not same as Bond #):</b></i></label>
                    <b-form-input
                      id="BondReference"
                      v-model="reference.BondReference"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="Bond Reference"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="User Defined"
                  rules="max:45"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label><i><b>User Defined:</b></i></label>
                    <b-form-input
                      id="UserDefined"
                      v-model="reference.UserDefined"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="UserDefined"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'IsfTransp',
  props: {
    isfid: { type: String, default: '' },
    shipmenttype: { type: String, default: '' },
    bondactivity: { type: String, default: '' },
    bondtype: { type: String, default: '' },
  },
  data() {
    return {
      required,
      USPortOptions: [],
      StateOptions: [],
      ForeignPortsOptions: [],
      FIRMSOptions: [],
      CarrierOptions: [],
      UOMOptions: [],
      reference: {
        EntryNumber: '',
        Carnet: '',
        BondReference: '',
        SuretyCode: '',
        UserDefined: '',
        ISFFilerName: '',
      },
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart()]).then(() => {
        this.LoadReferenceData().then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.reference.EntryNumber = respdata.entryNumber
          this.reference.Carnet = respdata.carnet
          this.reference.BondReference = respdata.bondReference
          this.reference.SuretyCode = respdata.suretyCode
          this.reference.UserDefined = respdata.userDefined
          this.reference.ISFFilerName = respdata.isfFilerName
          this.LoadingEnd()
        })
      })
    },
    LoadReferenceData() {
      return axios.get(`/isf/${this.isfid}/detail/reference`)
    },
    UpdateReference() {
      this.$refs.referenceRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ISFReferenceModel = {
            EntryNumber: this.reference.EntryNumber,
            Carnet: this.reference.Carnet,
            BondReferenceNumber: this.reference.BondReference,
            SuretyCode: this.reference.SuretyCode,
            UserDefined: this.reference.UserDefined,
            ISFFilerName: this.reference.ISFFilerName,
          }
          axios.put(`/isf/${this.isfid}/detail/reference`, ISFReferenceModel).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Reference data has been saved.')
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Reference data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Reference data cannot be saved.', 'Please check data requirements.')
        }
      })
    },

  },
}

</script>
