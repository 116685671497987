<template>
  <div>
    <b-row>
      <b-col md="12">
        <validation-observer
          ref="headerRules"
          tag="form"
        >
          <b-button-toolbar justify>
            <div>
              <b-form-checkbox v-model="header.IsActive">
                Active
              </b-form-checkbox>
            </div>
            <div>
              <b-button
                v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mb-1"
                @click="UpdateHeader()"
              >
                <font-awesome-icon
                  :icon="['far', 'save']"
                  size="lg"
                />
              </b-button>
            </div>
          </b-button-toolbar>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="ISF Action"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>ISF Action:</b></i></label>
                    <v-select
                      id="ISFActionOption"
                      v-model="header.ActionType"
                      :options="ISFActionOption"
                      label="text"
                      placeholder="Select the action"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Action Reason"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Action Reason:</b></i></label>
                    <v-select
                      id="ISFActionReasonOptions"
                      v-model="header.ActionReason"
                      :options="ISFActionReasonOptions"
                      label="text"
                      placeholder="Select the reason"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Customer"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Customer:</b></i></label>
                    <v-select
                      id="CustomerOption"
                      v-model="header.CustomerId"
                      :options="CustomerOptions"
                      label="name"
                      placeholder="Select a Customer"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="ISF Type"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>ISF Type:</b></i></label>
                    <v-select
                      id="ISFSubmissionOptions"
                      v-model="header.SubmissionType"
                      :options="ISFSubmissionOptions"
                      label="text"
                      placeholder="Select a Type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Shipment Type"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Shipment Type:</b></i></label>
                    <v-select
                      id="ShipmentTypeOptions"
                      v-model="header.ShipmentType"
                      :options="ShipmentTypeOptions"
                      label="text"
                      placeholder="Select a Shipment Type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Shipment Sub-Type"
                  :rules="`${CheckNull(header.ShipmentType) ? header.ShipmentType.value == '11' ? 'required' : '' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${CheckNull(header.ShipmentType) ? header.ShipmentType.value == '11' ? 'required' : '' : ''}`"><i><b>Shipment Sub-Type:</b></i></label>
                    <v-select
                      id="ShipmentSubTypeOptions"
                      v-model="header.SubShipmentType"
                      :options="ShipmentSubTypeOptions"
                      label="text"
                      placeholder="Select a Type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="SCAC"
                  rules="min:4|max:4|required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>SCAC:</b></i></label>
                    <b-form-input
                      id="SCAC"
                      v-model="header.SCAC"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="SCAC"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="MBOL #"
                  rules="max:50|required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>MBOL #:</b></i></label>
                    <b-form-input
                      id="MBOL"
                      v-model="header.MBOL"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="MBOL"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="MOT"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>MOT:</b></i></label>
                    <v-select
                      id="MOTOptions"
                      v-model="header.MOT"
                      :options="MOTOptions"
                      label="text"
                      placeholder="Select a MOT"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Qty"
                  rules="required|min_value:1|max_value:999999999|integer"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Estimated Qty:</b></i></label>
                    <b-form-input
                      id="Qty"
                      v-model="header.EstimatedQty"
                      type="number"
                      min="1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="UOM"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>UOM:</b></i></label>
                    <v-select
                      id="UOM"
                      v-model="header.UOM"
                      :options="UOMOptions"
                      label="codeName"
                      placeholder="Select the UOM"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated Weight"
                  rules="required|min_value:1|max_value:999999999|integer"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Estimated Weight (KG):</b></i></label>
                    <b-form-input
                      id="EstimatedWeight"
                      v-model="header.EstimatedWeight"
                      type="number"
                      min="1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Estimated Value"
                  rules="required|min_value:1|max_value:999999999|integer"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label class="required"><i><b>Estimated Value:</b></i></label>
                    <b-form-input
                      id="EstimatedValue"
                      v-model="header.EstimatedValue"
                      type="number"
                      min="1"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Bond
            </div>
          </div>
          <b-row>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Holder"
                  :rules="`${CheckNull(header.ShipmentType) ?header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required|max:12' : '' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${CheckNull(header.ShipmentType) ?header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required' : '' : ''}`"><i><b>Bond Holder ID:</b></i></label>
                    <b-form-input
                      id="BondHolder"
                      v-model="header.BondHolder"
                      v-uppercase
                      :state="errors.length > 0 ? false:null"
                      placeholder="BondHolder"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Type"
                  :rules="`${CheckNull(header.ShipmentType) ? header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required' : '' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${CheckNull(header.ShipmentType) ?header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required' : '' : ''}`"><i><b>Bond Type:</b></i></label>
                    <v-select
                      id="BondTypeOptions"
                      v-model="header.BondType"
                      :options="BondTypeOptions"
                      label="text"
                      placeholder="Select a type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Bond Activity"
                  :rules="`${CheckNull(header.ShipmentType) ?header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required' : '' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${CheckNull(header.ShipmentType) ?header.ShipmentType.value == '01' || header.ShipmentType.value == '02' ||header.ShipmentType.value == '07' || header.ShipmentType.value == '08' || header.ShipmentType.value == '10' ? 'required' : '' : ''}`"><i><b>Bond Activity Type:</b></i></label>
                    <v-select
                      id="BondActivityOptions"
                      v-model="header.BondActivity"
                      :options="BondActivityOptions"
                      label="text"
                      placeholder="Select a type"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="divider my-2">
            <div class="divider-text">
              Foreign reamin on board
            </div>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Port of Unlading"
                  :rules="`${header.SubmissionType.value == '2' || header.SubmissionType.value == '4' ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.SubmissionType.value == '2' || header.SubmissionType.value == '4' ? 'required' : ''}`"><i><b>Port of Unlading (Foreign):</b></i></label>
                    <v-select
                      id="POU"
                      v-model="frob.POU"
                      :options="ForeignPortsOptions"
                      label="foreignPOLName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Place of Delivery"
                  :rules="`${header.SubmissionType.value == '2' || header.SubmissionType.value == '4' ? 'required' : ''}`"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false:null"
                  >
                    <label :class="`${header.SubmissionType.value == '2' || header.SubmissionType.value == '4' ? 'required' : ''}`"><i><b>Place of Delivery (Foreign):</b></i></label>
                    <v-select
                      id="POD"
                      v-model="frob.POD"
                      :options="ForeignPortsOptions"
                      label="foreignPOLName"
                      placeholder="Select a port"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <div class="divider my-2">
          <div class="divider-text">
            {{ 'Last Updated: ' + header.LastUpdatedOn }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'

export default {
  name: 'IsfHeader',
  props: {
    isfid: { type: String, default: '' },
  },
  data() {
    return {
      required,
      CustomerOptions: [],
      CountryOptions: [],
      UOMOptions: [],
      ForeignPortsOptions: [],
      ISFActionOption: [
        { text: 'Add', value: 'A' },
        { text: 'Replace', value: 'R' },
        { text: 'Delete', value: 'R' },
      ],
      ISFSubmissionOptions: [
        { text: 'ISF-10', value: '1' },
        { text: 'ISF-5', value: '2' },
        { text: 'ISF-5 to 10', value: '3' },
        { text: 'ISF-10 to 5', value: '4' },
      ],
      ISFActionReasonOptions: [
        { text: 'Complete Transaction', value: 'CT' },
        { text: 'Fleible Range', value: 'FR' },
        { text: 'Flexible Timing', value: 'FT' },
        { text: 'Flexible Range and Timing', value: 'FX' },
      ],
      MOTOptions: [
        { text: 'Vessel, Non-container (10)', value: '10' },
        { text: 'Vessel, Container (11)', value: '11' },
      ],
      ShipmentTypeOptions: [
        { text: 'Standard or Regular Filings (01)', value: '01' },
        { text: 'To Order Shipments (02)', value: '02' },
        { text: 'HHG / PE (03)', value: '03' },
        { text: 'Military, Government (04)', value: '04' },
        { text: 'Diplomatic Shipment (05)', value: '05' },
        { text: 'Carnet (06)', value: '06' },
        { text: 'US Goods Returned (07)', value: '07' },
        { text: 'FTZ Shipments (08)', value: '08' },
        { text: 'International Mail Shipments (09)', value: '09' },
        { text: 'Outer Continental Shelf Shipments (10)', value: '10' },
        { text: 'Informal (11)', value: '11' },

      ],
      ShipmentSubTypeOptions: [
        { text: 'Section 321', value: '01' },
        { text: 'Informal Shipments', value: '02' },
        { text: 'General Note 3(e) Shipments', value: '03' },
      ],
      BondTypeOptions: [
        { text: 'Continuous (8)', value: '8' },
        { text: 'STB (9)', value: '9' },

      ],
      BondActivityOptions: [
        { text: 'Importer or Broker (01)', value: '01' },
        { text: 'Custodian of Bonded Merchandise (02)', value: '02' },
        { text: 'International Carrier (03)', value: '03' },
        { text: 'FTZ Operator (04)', value: '04' },
        { text: 'ISF Bond (16)', value: '16' },
      ],
      header: {
        ISFId: 0,
        ISFNumber: '',
        IsActive: true,
        CustomerId: '', // M
        ActionReason: '', // M
        ActionType: '', // M
        BondActivity: '',
        BondHolder: '',
        BondType: '',
        CountryOfIssuance: '',
        DOB: '',
        EstimatedQty: 0, // M // M
        EstimatedValue: 0, // M
        EstimatedWeight: 0, // M
        MBOL: '', // M
        MOT: '', // M
        SCAC: '', // M
        ShipmentType: '', // M
        Status: '',
        SubShipmentType: '',
        SubmissionType: '', // M
        UOM: '', // M
        LastUpdatedOn: '',
        LastUpdatedBy: '',
      },
      frob: {
        POU: '',
        POD: '',
      },
    }
  },
  computed: {
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  mounted() {

  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCustomerList(), this.LoadCountryList(), this.LoadUOM(), this.LoadForeignPorts()]).then(() => {
        this.LoadHeaderData().then(() => {
          this.LoadFROBData().then(respdata => {
            this.frob.POU = this.ForeignPortsOptions.find(option => option.foreignPOL === respdata.data[0].foreignPOU)
            this.frob.POD = this.ForeignPortsOptions.find(option => option.foreignPOL === respdata.data[0].pod)
          })
          this.LoadingEnd()
        })
      })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadUOM() {
      return axios.get('/abi/codes/uom')
        .then(rowData => {
          this.UOMOptions = rowData.data
        })
    },
    LoadForeignPorts() {
      return axios.get('/abi/codes/ports/foreign')
        .then(rowData => {
          this.ForeignPortsOptions = rowData.data
        })
    },
    LoadHeaderData() {
      //
      return axios.get(`/isf/${this.isfid}/detail/header`)
        .then(resp => {
          const response = resp.data
          const respdata = response[0]
          this.header.ActionReason = this.ISFActionReasonOptions.find(option => option.value === respdata.actionReason)
          this.header.CustomerId = this.CustomerOptions.find(option => option.id === respdata.customerId)
          this.header.ISFId = respdata.isfId
          this.header.ISFNumber = respdata.isfNumber
          this.header.IsActive = respdata.isActive
          this.header.ActionType = this.ISFActionOption.find(option => option.value === respdata.actionType)
          this.header.BondActivity = this.BondActivityOptions.find(option => option.value === respdata.bondActivity)
          this.header.BondHolder = respdata.bondHolder
          this.header.BondType = this.BondTypeOptions.find(option => option.value === respdata.bondType)
          this.header.CountryOfIssuance = this.CountryOptions.find(option => option.value === respdata.countryofIssuance)
          this.header.DOB = respdata.dob
          this.header.EstimatedQty = respdata.estimatedQty
          this.header.EstimatedValue = respdata.estimatedValue
          this.header.EstimatedWeight = respdata.estimatedWeight
          this.header.MBOL = respdata.mbol
          this.header.MOT = this.MOTOptions.find(option => option.value === respdata.mot)
          this.header.SCAC = respdata.scac
          this.header.ShipmentType = this.ShipmentTypeOptions.find(option => option.value === respdata.shipmentType)
          this.header.Status = respdata.status
          this.header.SubShipmentType = this.ShipmentSubTypeOptions.find(option => option.value === respdata.subShipmentType)
          this.header.SubmissionType = this.ISFSubmissionOptions.find(option => option.value === respdata.submissionType)
          this.header.UOM = this.UOMOptions.find(option => option.code === respdata.uom)
          this.header.LastUpdatedOn = respdata.lastUpdatedOn
          this.header.LastUpdatedBy = respdata.lastUpdatedBy
        })
    },
    LoadFROBData() {
      return axios.get(`/isf/${this.isfid}/detail/frob`)
    },
    UpdateHeader() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const ISFModel = {
            ActionReason: this.header.ActionReason.value,
            CustomerId: this.header.CustomerId.id,
            IsActive: this.header.IsActive,
            ActionType: this.header.ActionType.value,
            BondActivity: this.header.BondActivity.value,
            BondHolder: this.header.BondHolder,
            BondType: this.header.BondType.value,
            CountryOfIssuance: '',
            DOB: this.header.DOB,
            EstimatedQty: this.header.EstimatedQty,
            EstimatedValue: this.header.EstimatedValue,
            EstimatedWeight: this.header.EstimatedWeight,
            MBOL: this.header.MBOL,
            MOT: this.header.MOT.value,
            SCAC: this.header.SCAC,
            ShipmentType: this.header.ShipmentType.value,
            SubShipmentType: this.CheckNull(this.header.SubShipmentType) ? this.header.SubShipmentType.value : '',
            SubmissionType: this.header.SubmissionType.value,
            UOM: this.header.UOM.code,
          }
          const ISFFROBModel = {
            ForeignPOU_Q: 'K',
            ForeignPOU: this.CheckNull(this.frob.POU) ? this.frob.POU.foreignPOL : '',
            POD_Q: 'K',
            POD: this.CheckNull(this.frob.POD) ? this.frob.POD.foreignPOL : '',
          }
          axios.put(`/isf/${this.isfid}/detail/header`, ISFModel).then(() => {
            axios.put(`/isf/${this.isfid}/detail/frob`, ISFFROBModel).then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Header data has been saved.')
            })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayError('Header data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        } else {
          this.DisplayError('Header data cannot be saved.', 'Please check data requirements.')
        }
      })
    },
  },
}

</script>
